import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  algorithmRunResumeNodeTypes,
  algorithmRunStatuses,
  riskReliefSmartFunctionTypes,
} from '../../../../constants/constants'
import {
  useAlgorithmRunDetails,
  useSmartFunctionActions,
  useWorkspaceDetails,
} from '../../../../hooks'
import { SmartFunctionActionDropdownKeys } from '../../../../hooks/useSmartFunctionActions'
import notificationService from '../../../../services/notificationService'
import {
  AlgorithmRun,
  RiskReliefAlgorithmRun,
} from '../../../../store/reducers/workspaceReducer'
import { getCommonMenuItems } from './menuItems/SmartFunctionActionDropdownCommonMenuItems'
import { getWorkspaceTypeItems } from './menuItems/SmartFunctionActionDropdownWorkspaceTypeItems'

interface SmartFunctionActionDropdownProps {
  algorithmRun?: RiskReliefAlgorithmRun | AlgorithmRun
  disabled?: boolean
}

const SmartFunctionActionDropdown = ({
  algorithmRun,
  disabled,
}: SmartFunctionActionDropdownProps) => {
  const { t } = useTranslation()
  const { workspaceId, algorithmRunId } = useParams()
  const [fetchDone, setFetchDone] = useState(false)
  const [selectedAlgorithmRunId, setSelectedAlgorithmRunId] = useState<
    string | undefined
  >(algorithmRunId)

  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const { refreshAlgorithmRun, selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId: selectedAlgorithmRunId,
    workspaceId,
    preventFetch: true,
  })

  const { resumeSmartFunctionNode } = useSmartFunctionActions({
    workspaceId,
    algorithmRun,
    algorithmRunId,
  })

  useEffect(() => {
    if (algorithmRunId && algorithmRunId !== selectedAlgorithmRunId) {
      setSelectedAlgorithmRunId(algorithmRunId)
      setFetchDone(false)
    }
  }, [algorithmRunId, selectedAlgorithmRunId])

  useEffect(() => {
    const fetchData = async () => {
      if (
        selectedAlgorithmRunId &&
        !fetchDone &&
        selectedAlgorithmRun?.id !== selectedAlgorithmRunId
      ) {
        await refreshWorkspaceDetails()
        await refreshAlgorithmRun()
        setFetchDone(true)
      }
    }
    fetchData()
  }, [
    selectedAlgorithmRun,
    fetchDone,
    refreshAlgorithmRun,
    refreshWorkspaceDetails,
    selectedAlgorithmRunId,
  ])

  const {
    handleAlgorithmRunClick,
    handleCancelRunClick,
    handleExportRun,
    handlePrintContent,
    isRiskReliefRunAvailable,
  } = useSmartFunctionActions({
    workspaceId,
    algorithmRun,
    algorithmRunId,
  })

  const handleResume = async () => {
    if (!selectedWorkspace || !algorithmRun) return
    if (
      (algorithmRun as RiskReliefAlgorithmRun)?.smartFunctionType ===
      riskReliefSmartFunctionTypes.CAPA_GENERATION
    ) {
      await resumeSmartFunctionNode({
        workspaceId: workspaceId || '',
        runId: algorithmRun.id,
        nodeId: algorithmRunResumeNodeTypes.CAPA_GENERATION,
      })
    }
  }

  const handleRunClick = async () => {
    if (!selectedWorkspace || !algorithmRun) return
    if (
      (algorithmRun as RiskReliefAlgorithmRun)?.smartFunctionType ===
      riskReliefSmartFunctionTypes.CAPA_GENERATION
    ) {
      await handleResume()
    } else {
      await handleAlgorithmRunClick()
    }
  }

  const handleCancelRun = async () => {
    if (!selectedWorkspace || !algorithmRun) return
    try {
      await handleCancelRunClick()
      if (
        (algorithmRun as RiskReliefAlgorithmRun)?.smartFunctionType ===
        riskReliefSmartFunctionTypes.CAPA_GENERATION
      ) {
        notificationService.notificationError(
          t('smartFunctionDetailsPage.capaGenerationRunCancelled')
        )
      } else {
        notificationService.notificationError(
          t('smartFunctionDetailsPage.runCancelled')
        )
      }
    } catch (error) {
      console.error('Error cancelling run:', error)
    }
  }

  const handlers = {
    [SmartFunctionActionDropdownKeys.RUN_ALGORITHM]: handleRunClick,
    [SmartFunctionActionDropdownKeys.RESUME_RUN]: handleRunClick,
    [SmartFunctionActionDropdownKeys.CANCEL_RUN]: handleCancelRun,
    [SmartFunctionActionDropdownKeys.EXPORT_RUN]: handleExportRun,
    [SmartFunctionActionDropdownKeys.PRINT_CONTENT]: handlePrintContent,
  }

  const isRunsAvailable = useMemo(() => {
    if (!algorithmRun) return false

    if (
      (algorithmRun as RiskReliefAlgorithmRun)?.smartFunctionType ===
        riskReliefSmartFunctionTypes.CAPA_GENERATION ||
      (algorithmRun as RiskReliefAlgorithmRun)?.smartFunctionType ===
        riskReliefSmartFunctionTypes.ROOT_CAUSE_DISCOVERY ||
      (algorithmRun as RiskReliefAlgorithmRun)?.smartFunctionType ===
        riskReliefSmartFunctionTypes.DEVIATION_CLASSIFICATION
    ) {
      return isRiskReliefRunAvailable(algorithmRun)
    }

    return (
      !algorithmRun?.runStatus ||
      algorithmRun.runStatus === algorithmRunStatuses.COMPLETE ||
      algorithmRun.runStatus === algorithmRunStatuses.FAILED ||
      algorithmRun.runStatus === algorithmRunStatuses.READY ||
      algorithmRun.runStatus === algorithmRunStatuses.CANCELLED ||
      algorithmRun.runStatus === algorithmRunStatuses.PAUSED
    )
  }, [algorithmRun, isRiskReliefRunAvailable])

  const isStatusOngoing = useMemo(
    () =>
      algorithmRun?.runStatus === algorithmRunStatuses.ONGOING ||
      algorithmRun?.runStatus === algorithmRunStatuses.ONGOING_AI ||
      algorithmRun?.runStatus === algorithmRunStatuses.ONGOING_INGESTION,
    [algorithmRun]
  )

  const isResumeRun = useMemo(
    () =>
      (algorithmRun as RiskReliefAlgorithmRun)?.smartFunctionType ===
      riskReliefSmartFunctionTypes.CAPA_GENERATION,
    [algorithmRun]
  )

  const items = useMemo(
    () => [
      ...(getCommonMenuItems({
        isRunsAvailable,
        isStatusOngoing,
        algorithmRunId,
        algorithmRun,
        selectedWorkspace,
        isResumeRun,
      }) || []),
      ...(getWorkspaceTypeItems(
        selectedWorkspace?.typeCode,
        algorithmRun,
        selectedWorkspace
      ) || []),
    ],
    [
      isRunsAvailable,
      isStatusOngoing,
      algorithmRunId,
      algorithmRun,
      selectedWorkspace,
      isResumeRun,
    ]
  )

  return (
    <Dropdown
      menu={{
        items,
        onClick: ({ key, domEvent }) => {
          domEvent.stopPropagation()
          const handlerKey = key as keyof typeof handlers
          handlers[handlerKey]?.()
        },
      }}
      placement="bottomRight"
      trigger={['hover']}
      disabled={disabled}
    >
      <Button icon={<MoreOutlined />} onClick={(e) => e.stopPropagation()} />
    </Dropdown>
  )
}

export default SmartFunctionActionDropdown
