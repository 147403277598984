import { t } from 'i18next'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '.'
import {
  algorithmRunStatuses,
  riskReliefSmartFunctionTypes,
  workspaceTypes,
} from '../constants/constants'
import { routePaths } from '../pages/RootPage'
import { smartFunctionActionService, smartFunctionService } from '../services'
import notificationService from '../services/notificationService'
import {
  AlgorithmRun,
  AlgorithmRunConfigValues,
  RiskReliefAlgorithmRun,
  setSelectedAlgorithmRun,
} from '../store/reducers/workspaceReducer'

interface UseSmartFunctionActionsProps {
  workspaceId?: string
  algorithmRun?: RiskReliefAlgorithmRun | AlgorithmRun
  algorithmRunId?: string
}

export enum SmartFunctionActionDropdownKeys {
  RUN_ALGORITHM = 'RUN_ALGORITHM',
  RESUME_RUN = 'RESUME_RUN',
  CANCEL_RUN = 'CANCEL_RUN',
  BIOMARK_CONFIGURATION = 'BIOMARK_CONFIGURATION',
  REGSUB_CONFIGURATION = 'REGSUB_CONFIGURATION',
  DEL_CONFIGURATION = 'DEL_CONFIGURATION',
  VIEW_BIOMARK_RUN_SETUP = 'VIEW_BIOMARK_RUN_SETUP',
  VIEW_REGSUB_RUN_SETUP = 'VIEW_REGSUB_RUN_SETUP',
  VIEW_DEL_RUN_SETUP = 'VIEW_DEL_RUN_SETUP',
  EXPORT_RUN = 'EXPORT_RUN',
  PRINT_CONTENT = 'PRINT_CONTENT',
  RISK_RELIEF_CONFIGURATION = 'RISK_RELIEF_CONFIGURATION',
}

export interface MenuItemHandlers {
  handleAlgorithmRunClick: () => void
  handleCancelRunClick: () => void
  handleExportRun: () => void
  handlePrintContent: () => void
}

export const useSmartFunctionActions = ({
  workspaceId,
  algorithmRun,
  algorithmRunId,
}: UseSmartFunctionActionsProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { selectedWorkspace, refreshWorkspaceDetails, exportSmartFunctionCsv } =
    useWorkspaceDetails({
      workspaceId,
      preventFetch: true,
    })

  const { refreshAlgorithmRun, runAlgorithm, cancelAlgorithmRun } =
    useAlgorithmRunDetails({
      algorithmRunId,
      workspaceId,
      preventFetch: true,
    })

  const { deviationId } = useParams()

  const getBaseRoute = (typeCode?: string, pathname?: string) => {
    switch (typeCode) {
      case workspaceTypes.OFR_QR:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR
      case workspaceTypes.BIOMARK:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK
      case workspaceTypes.REGSUB:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB
      case workspaceTypes.DEL:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL
      case workspaceTypes.RISK_REL:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION
      case workspaceTypes.REFAI:
        return pathname?.includes(
          routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
        )
          ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
          : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
      default:
        return ''
    }
  }

  const handleAlgorithmRunClick = useCallback(async () => {
    if (!algorithmRun || !selectedWorkspace) return

    try {
      const { typeCode, id } = selectedWorkspace
      const config = await smartFunctionActionService.getAlgorithmConfig(
        typeCode,
        selectedWorkspace,
        deviationId,
        algorithmRun
      )

      const response = await runAlgorithm(workspaceId || '', config)

      const isRiskReliefRelatedPage =
        location.pathname.includes(
          routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_DEVIATION_CLASSIFICATION
        ) ||
        location.pathname.includes(
          routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_ROOT_CAUSE_DISCOVERY
        ) ||
        (location.pathname.includes(
          routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_RISK_REL_CAPA_GENERATION
        ) &&
          deviationId)

      const baseRoute = getBaseRoute(typeCode, location.pathname)
      const navigateTo = isRiskReliefRelatedPage
        ? `${baseRoute}/${id}/${response.id}/${deviationId}`
        : // other smart function pages
          `${baseRoute}/${id}/${response.id}`

      if (!location.pathname.includes(routePaths.WORKSPACE_DETAILS)) {
        navigate(navigateTo)
      } else {
        refreshWorkspaceDetails()
      }

      notificationService.notificationSuccess(
        t('smartFunctionDetailsPage.runStarted')
      )
    } catch (error) {
      console.error('Error running algorithm:', error)
      notificationService.notificationError(
        t('smartFunctionDetailsPage.runFailed')
      )
    } finally {
      refreshWorkspaceDetails()
      refreshAlgorithmRun()
    }
  }, [
    algorithmRun,
    selectedWorkspace,
    deviationId,
    runAlgorithm,
    workspaceId,
    location.pathname,
    navigate,
    refreshWorkspaceDetails,
    refreshAlgorithmRun,
  ])

  const handleCancelRunClick = useCallback(async () => {
    try {
      await cancelAlgorithmRun(
        selectedWorkspace?.id || '',
        algorithmRun?.id || '',
        navigate
      )
    } catch (error) {
      console.error('Error cancelling run:', error)
    } finally {
      refreshWorkspaceDetails()
      refreshAlgorithmRun()
    }
  }, [
    selectedWorkspace,
    algorithmRun,
    navigate,
    refreshWorkspaceDetails,
    refreshAlgorithmRun,
    cancelAlgorithmRun,
  ])

  const handleExportRun = useCallback(() => {
    if (selectedWorkspace && algorithmRun) {
      exportSmartFunctionCsv(selectedWorkspace.id, algorithmRun.id)
    }
  }, [selectedWorkspace, algorithmRun, exportSmartFunctionCsv])

  const handlePrintContent = useCallback(() => {
    window.print()
  }, [])

  interface ResumeSmartFunctionNodeProps {
    workspaceId: string
    runId: string
    nodeId: string
  }

  const resumeSmartFunctionNode = useCallback(
    async ({ workspaceId, runId, nodeId }: ResumeSmartFunctionNodeProps) => {
      try {
        if (!selectedWorkspace || !algorithmRun) return
        const config = await smartFunctionActionService.getAlgorithmConfig(
          selectedWorkspace?.typeCode || '',
          selectedWorkspace,
          deviationId,
          algorithmRun
        )
        if (!config) return

        const response = await smartFunctionService.resumeSmartFunctionNode({
          workspaceId,
          runId,
          nodeId,
          navigate,
          config: config as AlgorithmRunConfigValues,
        })

        if (response) {
          dispatch(setSelectedAlgorithmRun(response.data))
          refreshWorkspaceDetails()
          refreshAlgorithmRun()
        }
      } catch (error) {
        console.error('Error resuming CAPA generation:', error)
      }
    },
    [
      selectedWorkspace,
      algorithmRun,
      deviationId,
      navigate,
      dispatch,
      refreshWorkspaceDetails,
      refreshAlgorithmRun,
    ]
  )

  const isRiskReliefRunAvailable = (run?: RiskReliefAlgorithmRun): boolean => {
    if (!run) return false

    const { smartFunctionType, nodeStatuses, runStatus } = run

    const isAnyNodeOngoing = () => {
      // check if any node is ongoing
      return (
        nodeStatuses?.DEVIATION_CLASSIFICATION ===
          algorithmRunStatuses.ONGOING_INGESTION ||
        nodeStatuses?.DEVIATION_CLASSIFICATION ===
          algorithmRunStatuses.ONGOING ||
        nodeStatuses?.ROOT_CAUSE_DISCOVERY === algorithmRunStatuses.ONGOING ||
        nodeStatuses?.CAPA_GENERATION === algorithmRunStatuses.ONGOING
      )
    }

    const areDcAndRcdCompleted = () => {
      return (
        nodeStatuses?.DEVIATION_CLASSIFICATION ===
          algorithmRunStatuses.COMPLETE &&
        nodeStatuses?.ROOT_CAUSE_DISCOVERY === algorithmRunStatuses.COMPLETE
      )
    }

    const isRcdReady = () => {
      return (
        nodeStatuses?.ROOT_CAUSE_DISCOVERY === algorithmRunStatuses.COMPLETE ||
        nodeStatuses?.ROOT_CAUSE_DISCOVERY !== algorithmRunStatuses.FAILED ||
        nodeStatuses?.ROOT_CAUSE_DISCOVERY !== algorithmRunStatuses.CANCELLED
      )
    }

    if (
      smartFunctionType ===
      riskReliefSmartFunctionTypes.DEVIATION_CLASSIFICATION
    ) {
      if (isAnyNodeOngoing()) return false
      // DC Run Algorithm is enabled when DC & RCD are completed and CG is paused or if DC is completed and RCD is not ongoing
      return areDcAndRcdCompleted() || isRcdReady()
    }

    if (
      smartFunctionType === riskReliefSmartFunctionTypes.ROOT_CAUSE_DISCOVERY
    ) {
      // RCD Run Algorithm is disabled when any node is ongoing
      if (isAnyNodeOngoing()) return false
      // RCD Run Algorithm is disabled at all times (when RCD is Ready) if CG is paused
      return false
    }
    if (smartFunctionType === riskReliefSmartFunctionTypes.CAPA_GENERATION) {
      if (isAnyNodeOngoing()) return false
      if (areDcAndRcdCompleted()) {
        if (runStatus !== algorithmRunStatuses.PAUSED) return false
        return true
      }
      return runStatus === algorithmRunStatuses.PAUSED
    }

    return false
  }

  return {
    handleAlgorithmRunClick,
    handleCancelRunClick,
    handleExportRun,
    handlePrintContent,
    resumeSmartFunctionNode,
    isRiskReliefRunAvailable,
  }
}

export default useSmartFunctionActions
